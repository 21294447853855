import {createBrowserRouter} from "react-router-dom";
import {lazy, Suspense} from "react";
import ScreenLoader from "../components/general/ScreenLoader";

const OrdersList = lazy(() => import("../Pages/Orders/OrdersList.jsx"));
const Layout = lazy(() => import("../Layout/Layout.jsx"));
const Login = lazy(() => import("../Pages/Login.jsx"));

export const routerNames = {
  OrdersList: "/orders",
};

export const router = createBrowserRouter([
  {
    index: true,
    element: (
      <Suspense fallback={<ScreenLoader/>}>
        <Login/>
      </Suspense>
    ),
  },
  {
    path: routerNames.OrdersList,
    element: (
      <Suspense fallback={<ScreenLoader/>}>
        <Layout/>
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <OrdersList/>
          </Suspense>
        ),
      },
    ],
  },
]);
